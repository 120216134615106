import Banner from "../Components/Banner"
import Card from "../Components/Card"
import EnquiryForm from "../Components/EnquiryForm"

export default function Home() {
    return(
        <>
        <div className="container mx-auto">
            <div className="lg:grid grid-cols-4 gap-3">
                <div className="col-span-3 rounded">
                    <Banner />
                </div>
                <div className="hidden lg:block">
                    <EnquiryForm />
                </div>
            </div>
        </div>
        <div className="bg-yellow-500 text-white py-5 font-medium my-3 px-5 lg:px-64 text-center">
            We are specialized in bookkeeping, financial statements (P&L and BS) preparations and taxation (GST, CST and VAT)    
        </div>
        <div className="">
            <Card />
        </div>
        <div className="container mx-auto mt-5 lg:mt-0 lg:mb-72">
            <h1 className="font-bold text-yellow-500 text-xl text-center uppercase">
                Highlights of our services
            </h1>
            <ul className="lg:mx-64  mx-16 mt-5">
                <li className="list">Following the terms and conditions drafted in the mutual agreement between Clients and the Firm</li>
                <li className="list">We respect and fully maintain confidentiality of client’s information</li>
                <li className="list">Providing end to end services</li>
                <li className="list">Keeping trustworthy relationship</li>
            </ul>
        </div>
        <div className="mb-96 lg:hidden">
            <EnquiryForm />
        </div>
        </>
    )
}