import { useHistory } from "react-router-dom"
import Accounting from "../Assets/Images/accounts.png"
import Finance from "../Assets/Images/finance.png"
import Tax from "../Assets/Images/taxation.jpg"

export default function Card() {
    const history = useHistory()
    const service = [{
        name : "Accounting",
        desc : "We offer a full range of bookkeeping services for small business owners to comprehensively cover all your accounting needs.",
        image: Accounting
    },
    {
        name : "Finance",
        desc : "We offer preparation of financial statements like P&L and Balance Sheet. We provide support to Auditing and coverage of monthly book close.",
        image: Finance
    },
    {
        name : "Taxation",
        desc : "We offer filing of all Returns in connection with GST, CST Assessment and VAT Audit also provide statutory compliance with all departments.",
        image: Tax
    }]
    return (
        <div className="py-10 px-10 md:px-5 lg:px-28 container mx-auto grid md:grid-cols-3 gap-5">  
            {
                service.map((s)=> 
                    {
                        return  <div onClick={() => history.push("/service")} key={s.name} className="rounded cursor-pointer overflow-hidden shadow-lg  transition duration-500 ease-in-out transform hover:-translate-y-1 border-b-4 border-2 border-t-8 border-yellow-500">
                                    <img className="w-auto mx-auto my-2" src={s.image} alt={s.name} style={{height:"100px"}} />
                                    <div className="px-6 py-4 overflow-hidden border-yellow-500 border-t">
                                        <div className="font-bold text-xl mb-2 text-center text-yellow-500 uppercase">{s.name}</div>
                                        <p className="text-gray-700 text-base line-clamp-4">
                                            {s.desc}
                                        </p>
                                    </div>
                                </div>
                    }   
                )
            }
        </div>
    )
}