import emailjs from 'emailjs-com'

export default function SendMail(data,to = "Thathwamasi") {
    return  emailjs.sendForm("service_k03kjge","template_0j46mva",data,"qhqbGigofihMMmZ09")
            .then(() => {
                alert("Thank your for your Enquiry")
            })
            .catch((e) => {
                console.log(e)
                alert("Something went wrong; Try again later")
            })
}