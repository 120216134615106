
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Banner1 from "../Assets/Images/Banner1.png"
import Banner2 from "../Assets/Images/Banner2.png"
import Banner3 from "../Assets/Images/Banner3.png"

export default function Banner() {
    return(
        <Carousel interval={3000} autoPlay={true} showStatus={false} showThumbs={false} infiniteLoop={true}>
            <div>
                <img src={Banner1} alt="banner1" className="lg:rounded-md" />
                <p className="legend">Accounting</p>
            </div>
            <div>
                <img src={Banner2} alt="banner2" className="lg:rounded-md" />
                <p className="legend">Finance</p>
            </div>
            <div>
                <img src={Banner3} alt="banner3" className="lg:rounded-md" />
                <p className="legend">Taxation</p>
            </div>
        </Carousel>
    )
}