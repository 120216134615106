import EnquiryForm from "../Components/EnquiryForm"
import Enquiry from "../Assets/Images/Enquiry.svg"

export default function Contact() {
    return(
        <div className="max-w-screen">
            <div className="container mx-auto ">
                <p className="text-center font-bold text-lg text-white bg-yellow-500 w-52 mx-auto py-1 pr-6" style={{clipPath: "polygon(16% 0px, 90% 0px, 75% 100%, 0% 100%)"}}>CONTACT US</p><div className="mx-5 text-lg font-bold text-gray-700 mb-10 mt-10">
                    <div className="text-center">
                        <p className="text-xl text-yellow-500 mb-3 uppercase">Registered Office</p>
                        <div>Thathwamasi Associates <br />GSTIN: 33BABPR6900E1Z2 <br />S2, Varunavi Illam, 2nd Floor, Akshaya Flat, <br />No.23, Indira Gandhi Street, Senthil Nagar, <br />Thirumullaivoyal, Chennai 600-062.
                        </div>
                    </div>
                    <p className="text-xl text-yellow-500 mb-3 uppercase text-center mt-10">Branches</p>
                    <div className="grid lg:grid-cols-3">
                        <div className="text-center mt-7">
                            <p className="text-xl text-yellow-500 mb-3 uppercase">Kancheepuram Branch</p>
                            <div>No. 36, Katcheri Lane Street, <br />Kancheepuram 603-110. <br />Email: <a href="mailto:psvp.rams@gmail.com" className="underline">kancheepuram.ta@gmail.com</a>
                            </div>
                        </div>
                        <div className="text-center mt-7">
                            <p className="text-xl text-yellow-500 mb-3 uppercase">Chennai Branch</p>
                            <div className="not-italic">19/9, Ramarao Road, <br /> Mylapore, <br />Chennai 600-004.<br />Email: <a href="mailto:rajappamanikandan@gmail.com" className="underline">chennai.ta@gmail.com</a>
                            </div>
                        </div>
                        <div className="text-center mt-7">
                            <p className="text-xl text-yellow-500 mb-3 uppercase">Tirunelveli  Branch</p>
                            <div className="not-italic">No. 27, Vinayagar Nagar, <br /> N. G. O 'A' Colony <br /> Tirunelveli 627 007.<br />Email: <a href="mailto:rajappamanikandan@gmail.com" className="underline">tirunelveli.ta@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-10 mb-96 border-t-2 border-yellow-500">
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="lg:mx-10 mx-5 mt-auto">
                        <img src={Enquiry} alt="Enqiry"/>
                    </div>
                    <div className="lg:mx-20">
                        <EnquiryForm />
                    </div>
                </div>
            </div>
        </div>
    )
}