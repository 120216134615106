import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import ScrollToTop from './ScrollToTop'

import Header from "./Components/Header"
import Navbar from "./Components/Navbar"
import Footer from "./Components/Footer"
import Home from './Layouts/Home'
import About from './Layouts/About'
import Client from './Layouts/Client'
import Contact from './Layouts/Contact'
import Service from './Layouts/Service'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="border-t-8 border-b-4 nunito relative overflow-hidden border-yellow-500 min-h-screen " style={{fontFamily: "'Nunito', sans-serif"}}>
        <Header />
        <Navbar />
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/clients" component={Client} />
          <Route path="/contact" component={Contact} />
          <Route path="/service" component={Service} />
          <Route path="/" component={Home} />

        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
