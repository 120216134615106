import Logo from "../Assets/Images/logo.jpg"

export default function Header() {
    return(
        <div className="sm:container mx-auto">
                <img className="inline lg:w-60 w-52" src={Logo} alt="logo" />
            <div className="float-right my-2 sm:my-4 inline ">
                <p className="bg-yellow-500 ml-auto w-60 h-8 pt-1 mr-1 rounded-md pl-20 text-white" style={{clipPath: "polygon(20% 0%, 100% 0, 100% 100%, 0% 100%)"}}>
                   <a href="tel:+91 9976330007" className="no-underline">+91 9976330007</a>
                </p>
                <p className="bg-yellow-500 w-80 h-8 pt-1 mt-1 mr-1 rounded-md pl-12 text-white" style={{clipPath: "polygon(20% 0%, 100% 0, 100% 100%, 0% 100%)"}}>
                   <a href="mailto:thathwamasiassociates@gmail.com" className="no-underline">thathwamasiassociates@gmail.com</a>
                </p>
            </div>
        </div>
    )
}