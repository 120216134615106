export default function Footer() {
    return(
        <div className="bg-gray-700 absolute inset-x-0 bottom-0 left-0 w-full text-gray-300">
            <div className="container mx-auto md:grid grid-cols-3 text-sm pb-3 pt-20">
                <div className="text-center lg:hidden mb-10 col-span-3">
                    <p className="text-xl text-yellow-500 mb-3 uppercase">Contact us</p>
                    <div>Thathwamasi Associates <br />GSTIN: 33BABPR6900E1Z2 <br />S2, Varunavi Illam, 2nd Floor, Akshaya Flat, <br />No.23, Indira Gandhi Street, Senthil Nagar, <br />Thirumullaivoyal, Chennai 600-062. ,<br /> PH : +91 9976330007 ,<br /> Email : <a href="mailto:thathwamasiassociates@gmail.com"> thathwamasiassociates@gmail.com</a> 
                    </div>
                </div>
                <p className="text-center sm:float-left mt-auto">
                    Copy Rigths © 2021 Thathwamasi
                </p>
                <div>
                    <div className="text-center">
                        <p className="text-xl  hidden lg:block text-yellow-500 mb-3 uppercase">Contact us</p>
                        <div className=" hidden lg:block">Thathwamasi Associates <br />GSTIN: 33BABPR6900E1Z2 <br />S2, Varunavi Illam, 2nd Floor, Akshaya Flat, <br />No.23, Indira Gandhi Street, Senthil Nagar, <br />Thirumullaivoyal, Chennai 600-062.,<br /> PH : +91 9976330007 ,<br /> Email : <a href="mailto:thathwamasiassociates@gmail.com"> thathwamasiassociates@gmail.com</a> 
                        </div>
                    </div>
                </div>
                <p className="text-center sm:float-right mt-auto">
                    Created by VTV Creations
                </p>
            </div>
        </div>
    )
}