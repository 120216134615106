import Account from "../Assets/Images/account.svg"
import Finance from "../Assets/Images/finance.svg"
import Tax from "../Assets/Images/taxation.svg"

export default function Service() {
    return(
        <div className="max-w-screen mb-96">
            <div className="container mx-auto ">
                <p className="text-center font-bold text-lg text-white bg-yellow-500 w-48 mx-auto py-1 pr-6" style={{clipPath: "polygon(16% 0px, 90% 0px, 75% 100%, 0% 100%)"}}>
                    SERVICES
                </p>
            </div>
            <div className="w-screen p-8  mt-2" id="accounting">
                <div className="container mx-auto">
                    <p className="text-gray-700 font-bold text-center w-3/4 mx-auto">
                        We offer the following professional services with competent fee structures which provide better value for our clients. We are always at your easy reach and assure our best services
                    </p>
                </div>
            </div>
            <div className="container mx-auto mt-10 lg:grid grid-cols-2 ">
                <div id="accounting">
                    <img src={Account} className="w-3/4 h-3/4 mx-auto" alt="Accounts" />
                </div>
                <div className="my-auto">
                    <h1 className="font-bold lg:-mt-20 mt-5 text-center text-yellow-500 text-xl uppercase">
                        Accounting
                    </h1>
                    <p className="text-center text-yellow-500 opacity-80 uppercase text-md font-medium">
                        Bookkeeping
                    </p>
                    <p className="mt-5 w-3/4 mx-auto text-center">
                        We offer a full range of bookkeeping services for small business owners to comprehensively cover all your accounting needs.
                    </p>
                </div>
                <div className="block lg:hidden mt-16">
                    <img src={Finance} className="w-3/4 h-3/4 mx-auto" alt="Accounts" />
                </div>
                <div className="my-auto" id="finance">
                    <h1 className="font-bold mt-5 lg:-mt-20 text-center text-yellow-500 text-xl uppercase">
                        Finance
                    </h1>
                    <p className="text-center text-yellow-500 opacity-80 uppercase text-md font-medium">
                        Financials
                    </p>
                    <p className="mt-5 w-3/4 mx-auto text-center">
                        We offer preparation of financial statements like P&amp;L and Balance Sheet. We provide support to Auditing and coverage of monthly book close.
                    </p>
                </div>
                <div className="lg:block hidden">
                    <img src={Finance} className="w-3/4 h-3/4 mx-auto" alt="Accounts" />
                </div>
                <div className="mt-10 lg:mt-2">
                    <img src={Tax} className="w-3/4 mx-auto h-3/4" alt="tax" />
                </div>
                <div className="my-auto">
                    <h1 className="font-bold mt-14 lg:-mt-20 text-center text-yellow-500 text-xl uppercase">
                        Taxation
                    </h1>
                    <p className="text-center text-yellow-500 opacity-80 uppercase text-md font-medium">
                        GST, CST, VAT, IT, PF &amp; ESI
                    </p>
                    <p className="mt-5 w-3/4 mx-auto text-center">
                        We offer filing of all Returns in connection with GST, CST Assessment and VAT Audit also provide statutory compliance with all departments.
                    </p>
                </div>
            </div>
            
        </div>
    )
}