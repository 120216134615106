import Vission from "../Assets/Images/vision.svg"
import Mission from "../Assets/Images/mission.svg"

export default function About() {
    return(
        <>
        <div className="max-w-screen">
            <div className="text-center">
                <p className="text-center font-bold text-lg text-white bg-yellow-500 w-48 mx-auto py-1 pr-6" style={{clipPath: "polygon(16% 0px, 90% 0px, 75% 100%, 0% 100%)"}}>ABOUT US</p>
                <p className="mx-5 lg:mx-64 text-lg font-bold text-gray-700 text-center mt-10">
                Thathwamasi Associates is a sole proprietorship firm founded in April 2018 based out of Chennai, India. We are specialized in bookkeeping, financial statements (P&L and BS) preparations and taxation (GST, CST and VAT). We started this firm to assist our clients especially the Traders, Micro Small and Medium (MSME) sized business units through our knowledge and experience
                </p>
            </div>
        </div>
        <div className="container mx-auto mt-10 lg:px-20 mb-96">
            <div className="lg:grid grid-cols-2">
                <img className="w-3/4 mx-auto" src={Vission} alt="vision" />
                <div className="my-auto">
                    <p className="text-xl uppercase lg:-mt-5 mt-5 text-center text-yellow-500 font-bold">
                        Vision
                    </p>
                    <p className="text-lg mt-5 text-center">
                    To become a Global Business Partner by providing world class support services in financing and accounting.
                    </p>
                </div>
            </div>
            <div className="lg:grid grid-cols-2 mt-10">
                <div className="my-auto">
                    <img className="w-3/4 mx-auto lg:hidden" src={Mission} alt="Mission" />
                    <p className="text-xl uppercase lg:-mt-5 mt-5 text-center text-yellow-500 font-bold">
                        Mission
                    </p>
                    <div className="text-lg mt-5 text-center">
                        <ul>
                            <li>To maintain highest professional standards of independence, integrity, ethics and objectivity at all times.</li>
                            <li>Provide innovative and out of box solutions with a view to provide value enhancement for our clients.</li>
                            <li>Provide timely, focused and issue-based advice to our clients, keeping up with the dynamic needs of clients.</li>
                        </ul>
                    </div>
                </div>
                <img className="w-3/4 mx-auto lg:block hidden" src={Mission} alt="Mission" />
            </div>
        </div>
        </>
    )
}