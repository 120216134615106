import SendMail from "../Assets/sendemail/email"

export default function EnquiryForm() {
    const inputClass = "appearance-none bg-transparent border-b placeholder-gray-300 border-white w-full text-white mr-3 py-2 px-2 leading-tight focus:outline-none"
    function HandleSubmit(e) {
        e.preventDefault()
        SendMail(e.target)
        e.target.reset()
    }

    return(
        <div className="col-span-1 m-5 lg:m-0 h-full bg-yellow-500 text-white pt-5 rounded-lg p-4">
            <p className="font-bold text-lg text-center uppercase">Enquiry Form</p>
            <div className="mt-7 text-md">
            <form className="contact-form" onSubmit={HandleSubmit}>
                <div className="grid grid-cols-4 gap-3 mt-2">
                    <div className="uppercase mt-2">
                        Name
                    </div>
                    <div className="col-span-3">
                        <input name="from_name" autoComplete="off" className={inputClass} type="text" placeholder="Jane Doe" required aria-label="Full name"></input>
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-3 mt-2">
                    <div className="uppercase mt-2">
                        Mobile
                    </div>
                    <div className="col-span-3">
                        <input name="phone" autoComplete="off" type="number" className={inputClass} placeholder="xxxxxxxxxx" required aria-label="Full name"></input>
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-3 mt-2">
                    <div className="uppercase mt-2">
                        Email
                    </div>
                    <div className="col-span-3">
                        <input name="email" autoComplete="off" className={inputClass} type="email" placeholder="janedoe@abc.com" required aria-label="Full name"></input>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="uppercase my-3">
                        Message
                    </div>
                    <textarea name="message" autoComplete="off" className="w-full p-2 bg-gray-200 text-gray-700 border rounded-lg focus:outline-none" rows="3" required></textarea>
                </div>
                <div className="my-2">
                    <button className="bg-transparent uppercase hover:bg-white text-white font-semibold hover:text-yellow-500 py-2 px-4 border border-white hover:border-yellow-500 w-full rounded">
                        Submit
                    </button>
                </div>
                </form>
            </div>
        </div>
    )
}